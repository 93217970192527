import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Swal from 'sweetalert2';
import React, { useMemo, useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component/slider.css"

const EditPlan = (props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("Sehr geehrte Damen und Herren, <br/><br/>hiermit bestätigen wir Ihnen, Ihren Termin mit unserem Techniker. <br/><br/>Sollten Sie diesen Termin nicht wahrnehmen können, bitten wir Sie uns unter der info@bayerische-landeswerke.de zu informieren. <br/><br/>Bitte zögern Sie nicht, im Falle von Unklarheiten oder Rückfragen, mit uns Kontakt aufzunehmen. <br/><br/>Gerne steht Ihnen unser Team zu unseren Geschäftszeiten Mo. – Do. von 09:00 – bis 18:00 Uhr und Fr. von 09:00 bis 15:00 Uhr unter 08131 / 3328111 oder jederzeit per eMail unter info@bayerische-landeswerke.de zur Verfügung.<br/><br/>Ihr BLW Team<br/>BLW Landeswerke GmbH<br/>+49 8131 3328111 l info@bayerische-landeswerke.de");
  const [refresh, setRefresh] = useState(0);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState([]);
  const [isVisible,setIsVisible] = useState(0)

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();


  /*
  useEffect(() => {
    callFetch("assign-order-processing/create", "GET", []).then((res) => {
      setEmployees(res?.data?.employees);  
    });
  }, [refresh,isVisible]);
  */

  useEffect(()=>{    
    if(!props?.data?.id) return;

    callFetch("assign-order-processing/" +props?.data?.id + "/edit", "GET", []).then((res) => {
        setEmployees(res?.data?.employees);
        setData(res?.data?.data);
    });

    /*
    console.log('props')
    console.log(props)
    setValue('dachmontag_elektromontage', props.title == 'DC' ? 'Dachmontage' : 'Elektromontage');
    */
  },[props]);

  useEffect(()=>{
    if(!data?.id) return;

    for (let [key, value] of Object.entries(data)) {
        setValue(key, value);
    }
  },[data]);

  const sendEmail = (e) => {
    if(!data?.id) return;
    e.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({ 
        text: t("Are you sure to send this E-mail?"),
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Yes, do it!'),
        cancelButtonText: t('Cancel')
    }).then((result) => {
        if (result.isConfirmed) {
            var formData = new FormData();
            formData.description = description;
            formData.project_id = data.project_id;
            formData.miltarbeiter = getValues('miltarbeiter');
            formData.time = getValues('time'); 
            formData.date = getValues('date'); 
            formData.dachmontag_elektromontage = getValues('dachmontag_elektromontage'); 
            callFetch("send-new-plan-email", "POST", formData, setError).then((res) => {
              console.log(res.data);
              setSaving(false);
              if (!res.ok) return;
              setSubmitSuccess(true);
            });
        }
    });
  }
  
  const onSubmit = (formData) => {
    if(!data?.id){alert('id not found'); return;}
    setSaving(true);
    formData.project_id = data.project_id;
    formData.description = description;

    callFetch("assign-order-processing/" + data.id, "POST", formData, setError).then((res) => {
      console.log('edit plan submit')
      
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
      props.setRefresh(data?.project_id);
      var projectInfoModalClose = document.getElementById('triggerEditPlanClose');
      projectInfoModalClose.click();
      setValue('date','')
      setValue('time','')
      setValue('miltarbeiter','')
      setValue('dachmontag_elektromontage','')
      setValue('description','')
    });
  };
  
  return (
    <>

      <button type="button" id="triggerEditPlanBtn" className="btn bg-gradient-primary d-none" data-bs-toggle="modal" data-bs-target="#triggerEditPlan">Edit Plan</button>
      <div
        className="modal fade"
        id="triggerEditPlan"
        tabindex="-1"
        role="dialog"
        aria-labelledby="triggerEditPlanLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="triggerEditPlanLabel">
                {t("Edit Plan")}
              </h5>
              <button
                type="button"
                id="triggerEditPlanClose"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body apps-icon-link">
              <form
                className={`needs-validation ${
                  Object.keys(errors).length ? "was-validated" : ""
                }`}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
               
               <div className="row">
                  <div className="col-sm-6">
                      <label>{t("Datum")} *</label>
                      <input
                        type="date"
                        className="form-control mb-2 flatpickr"
                        placeholder={t("eg. 16-04-2022")}
                        {...register("date", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label>{t("Uhrzeit")} *</label>
                      <select
                        className="form-control"
                        {...register("time", {
                          required: true,
                        })}
                        required
                      >
                        <option value="08:00">08:00 - 10:00</option>
                        <option value="10:00">10:00 - 12:00</option>
                        <option value="12:00">12:00 - 14:00</option>
                        <option value="14:00">14:00 - 16:00</option>
                        <option value="16:00">16:00 - 18:00</option>
                        <option value="18:00">18:00 - 20:00</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.date && errors.date.message}
                      </div>
                    </div>
               </div>

                <div className="row">
                  <div className="col-sm-6">
                    <label>{t("Employees")} *</label>
                    <select
                      className="form-control mb-2"
                      {...register("miltarbeiter", {
                        required: true,
                      })}
                      required
                    >
                      <option>----</option>
                      {employees?.map((technician, index) => (
                        <option value={technician.id}>
                          {technician.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {errors.employees && errors.employees.message}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("Dachmontage/Elektromontage")} *</label>
                    <select
                      className="form-control mb-2"
                      {...register("dachmontag_elektromontage", {
                        required: true,
                      })}
                      required
                      readOnly={true}
                    >
                      <option value="Dachmontage">Dachmontage</option>
                      <option value="Elektromontage">Elektromontage</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>
                </div>

                <div>
                <SoftBox id="commissioning"
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%" >
                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('E-mail')}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftEditor style={{height: '220px'}}
                                    value={description}
                                    onChange={setDescription}
                                />
                            </SoftBox> 
                   
                </div>
                <br/> 
                <div className="col-12 mt-5">
                
                  {!saving && (
                    <button type="submit" className="btn btn-primary float-end ml-2">
                      {t("Save")}
                    </button>
                  )}
                  {saving && (
                    <button
                      type="submit"
                      className="btn btn-disabled float-end"
                      disabled
                    >
                      {t("Saving ...")}
                    </button>
                  )}

              {!saving && (
                    <button type="submit" onClick={(e) => sendEmail(e)}  className="btn btn-primary float-end mr-2" style={{marginRight: '15px'}}>
                      {t("Send E-mail")}
                    </button>
                  )}
                  {saving && (
                    <button
                      type="submit"
                      className="btn btn-disabled float-end"
                      disabled
                      style={{marginRight: '15px'}}>
                      {t("Sending ...")}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPlan;
