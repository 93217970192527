import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useParams } from "react-router-dom";

import Cookies from 'js-cookie';
import { callFetch } from "../../../helpers/callFetch";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SoftButton from "components/SoftButton";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NavMenu(props) {
    let params = useParams();
    const { t } = useTranslation();
    const errorNotify = () => toast(t("Fill Out Lead"));
    const [activeTab, setActiveTab] = useState('callcenter');
    const [saveBtn, setSaveBtn] = useState(false);
    const [saveBtnTwo, setSaveBtnTwo] = useState(false);
    const [saveBtnThree, setSaveBtnThree] = useState(false);
    const [actionBtn, setActionBtn] = useState(true);
    const [dcabname,setDcabname] = useState(false)
    const [data, setData] = useState([]); 
    const [invoices, setInvoices] = useState([]); 
    const [deActiveDist, setDeActiveDist] = useState(false); 
    const [quoatationSuccess, setQuoatationSuccess] = useState(true);
    const callCenter = useRef(null);
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const activeDataTab = (data) => {
        if(data == 'callcenter'){
            setActiveTab(data);
            props.activeTab(data);

            setSaveBtn(false);
            setSaveBtnTwo(false);
            setActionBtn(true);
            setDcabname(false)
        }else{
            if (params?.id) {
                setActiveTab(data);
                props.activeTab(data);

                if(data == 'auftrag'){    
                    setActionBtn(true);
                    setSaveBtnTwo(true);
                    setSaveBtn(false);
                    setSaveBtnThree(false);
                    setDcabname(false)
                }else if(data == 'distribution'){                
                    setSaveBtn(true);
                    setActionBtn(true);
                    setSaveBtnTwo(false);
                    setSaveBtnThree(false);
                    setDcabname(false)               
                }else if(data == 'auftrag'){ 
                    setActionBtn(true);
                    setSaveBtnTwo(true);
                    setSaveBtn(false);
                    setSaveBtnThree(false);
                    setDcabname(false)
                }else if(data == 'order_processing'){
                    setSaveBtn(false);
                    setActionBtn(true);
                    setSaveBtnTwo(false); 
                    setSaveBtnThree(false);
                    setDcabname(true)                    
                }else if(data == 'digitalsign'){
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setSaveBtnThree(true);
                    setActionBtn(true);
                    setDcabname(false)
                }
                else if (data == 'accounting') {
                    setActionBtn(true);
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setDcabname(false)                
                }else{
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setActionBtn(true);
                    setDcabname(false)
                }        
            }else{
                errorNotify();
                setActionBtn(true)
                setSaveBtn(false);
                setSaveBtnTwo(false);
                activeDataTab('callcenter');
                setDeActiveDist(true);
                callCenter.current.classList.add('active');
                document.querySelector('.moving-tab').style.transform = 'translate3d(-6px, 0px, 0px)';  
            }
        }
    }

    const renderDestributaionActionMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={() => { closeMenu(); document.getElementById('auftrag').click(); }}>{t('Create Order')}</MenuItem>
            <MenuItem onClick={closeMenu}>{t('Download Quotation PDF')}</MenuItem>
        </Menu>
    );

    function editDst() {
        document.getElementById('editdstBtn').click();
    }

    function saveOrder() {
        document.getElementById('saveOrderBtn').click();
    }

    function saveOrderSign() {
        document.getElementById('saveSign').click();
    }
    
    function DcAcAbnahmeprotokoll(){
        if(document.getElementById('acAbname')){
            document.getElementById('acAbname').click();
            console.log('acAbname save')
        }

        if(document.getElementById('saveSign')){
            document.getElementById('saveSign').click();
            console.log('saveSign save')
        }

        if(document.getElementById('dcAbname')){
            document.getElementById('dcAbname').click();
            console.log('dcAbname save')
        }
    }

    
    useEffect(() => {
        if(!params.id) return;
        callFetch("project/related/id/get/"+params.id, "GET", []).then((res) => {                           
            setData(res?.data);
        });
    }, [props, params?.id]);
   

return(
        <>
        <div className="row align-items-center">
            <div className="col-lg-9 col-sm-12">
                <div className="nav-wrapper position-relative end-0">
                    <ul className="nav nav-pills nav-fill bg-white blur shadow-blur p-1 projecttab" role="tablist">

                        {Cookies.get('permissions').indexOf("project_call_center-read") !== -1 ? (
                        <li className="nav-item" id="callcenter" role="presentation">
                            <a ref={callCenter} onClick={()=>{activeDataTab('callcenter')}} href="#" className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" role="tab" aria-selected="true">
                                {t('Lead')}
                            </a>
                        </li>
                            ) : <></>}
                            
                        {Cookies.get('permissions').indexOf("project_distribution-read") !== -1 ? (
                        <li className="nav-item" id="distribution_li">
                            {deActiveDist ? <a style={{boxShadow: 'none'}} onClick={()=>{activeDataTab('distribution')}} id="distribution" href="#" className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Distribution')}
                            </a> : ''}
                            {!deActiveDist ? <a onClick={()=>{activeDataTab('distribution')}} id="distribution" href="#" className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Distribution')}
                            </a> : ''}
                            
                        </li>
                        ) : <></>}

                        {Cookies.get('permissions').indexOf("order_project-read") !== -1 ? (
                        <li className="nav-item">
                            <a onClick={()=>{activeDataTab('auftrag')}} href="#" className="nav-link mb-0 px-0 py-1 " id={"auftrag"} data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Auftrag')}
                            </a>
                        </li>
                        ) : <></>} 
                        
                        {/*Cookies.get('permissions').indexOf("roofplanner-read") !== -1 ? (
                        <li className="nav-item" role="presentation">
                            <a onClick={() => { activeDataTab('roof-planner') }} href="#" className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Roof Planner')}
                            </a>
                        </li>
                        ) : <></>*/}

                        {Cookies.get('permissions').indexOf("roofplanner-read") !== -1 ? (
                        <li className="nav-item" role="presentation">
                            <a onClick={() => { activeDataTab('roof-planner') }} href="#" className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Roof Planner')}
                            </a>
                        </li>
                        ) : <></>} 
                        
                        {Cookies.get('permissions').indexOf("project_order_processing-read") !== -1 ? (
                        <li className="nav-item">
                            <a onClick={()=> {
                                    if(params?.id){
                                        activeDataTab('order_processing');
                                    }else{
                                        errorNotify();
                                    }
                                   // activeDataTab('order_processing')
                                }} href="#" id="order_processing" className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Order processing')}
                            </a>
                        </li>
                        ) : <></>}
                        
                        {Cookies.get('permissions').indexOf("project_files-read") !== -1 ? (
                        <li className="nav-item">
                            <a onClick={()=>{activeDataTab('files')}} href="#" className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Files')}
                            </a>
                        </li>
                        ) : <></>}
                        
                        {Cookies.get('permissions').indexOf("project_notes-read") !== -1 ? (
                        <li className="nav-item">
                            <a onClick={()=>{activeDataTab('notes')}} href="#" className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Notes')}
                            </a>
                        </li>
                        ) : <></>}
                        
                        {Cookies.get('permissions').indexOf("project_accounting-read") !== -1 ? (
                        <li className="nav-item">
                            <a onClick={()=>{
                                    if(params?.id){
                                        callFetch("dc_abnahmeprotokoll/"+params?.id+"/edit","GET",[]).then((res)=>{
                                            console.log(res)
                                            if(res?.data?.dcabname?.comisioning_status == '1'){
                                                activeDataTab('accounting')
                                            }else{
                                                toast(t("Fill Out DC Abnahmeprotokol"));
                                            }
                                        })
                                    }else{
                                        errorNotify();
                                    }                                    
                                }}
                                href="#" className="nav-link mb-0 px-0 py-1 " id={"invoices"} data-bs-toggle="tab" role="tab" aria-selected="false">
                                {t('Accounting')}
                            </a>
                        </li>
                        ) : <></>}
                    </ul>
                </div>
            </div>
            <div className="col-lg-3 col-sm-12 res-options">
                {saveBtn ? (
                    <button className="btn btn-primary mb-0" onClick={() => editDst()}>{t('Save')}</button>
                ) : <></>}

                {saveBtnTwo ? (
                    <button className="btn btn-primary mb-0" onClick={() => saveOrder()}>{t('Save')}</button>
                ) : <></>}
                
                {saveBtnThree ? (
                    <button className="btn btn-primary mb-0" onClick={() => saveOrderSign()}>{t('Save')}</button>
                ) : <></>}

                {(dcabname && Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1) || (dcabname && Cookies.get('permissions').indexOf("ac_abnahmeprotokoll-update") !== -1) ? (
                    <button className="btn btn-primary mb-0" onClick={() => DcAcAbnahmeprotokoll()}>{t('Save')}</button>
                ) : <></>}

                {actionBtn ? (
                    <>
                        <SoftButton className="ms-3" variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                            {t('Actions')}&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </SoftButton>
                        <Menu
                            anchorEl={menu}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            open={Boolean(menu)}
                            onClose={closeMenu}
                            keepMounted
                            >
                                <>
                                    {activeTab == 'distribution' ? (
                                        <>
                                            <MenuItem onClick={() => { closeMenu(); document.getElementById('auftrag').click(); }}>{t('Create Order')}</MenuItem>
                                            {/* <MenuItem onClick={()=>{ closeMenu(); document.getElementById('auftrag').click(); }}>{t('Create Order')}</MenuItem> */}
                                            <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.quations?.id} >{t('Quotation PDF')}</a></MenuItem>
                                            <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation-new/' + data?.quations?.id} >{t('Data aquision sheet')}</a></MenuItem>
                                        </>
                                    ) : activeTab == 'auftrag' ? (
                                        <>
                                            <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.quations?.order?.id} >{t('Order PDF')}</a></MenuItem>
                                            {Cookies.get('permissions').indexOf("invoice-create") !== -1 ? (
                                                <MenuItem onClick={() => { closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                            ) : <></>}
                                            {Cookies.get('permissions').indexOf("order_project-read") !== -1 ? (
                                                <MenuItem onClick={() => { closeMenu(); activeDataTab('digitalsign') }}>{t('Sign Order')}</MenuItem>
                                            ) : <></>}
                                        </>
                                    ) : activeTab == 'digitalsign' ? (
                                        <>
                                            <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.quations?.order?.id} >{t('Order PDF')}</a></MenuItem>
                                            {Cookies.get('permissions').indexOf("invoice-create") !== -1 ? (
                                                <MenuItem onClick={() => { closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                            ) : <></>}
                                            {Cookies.get('permissions').indexOf("order_project-read") !== -1 ? (
                                                <MenuItem onClick={() => { closeMenu(); activeDataTab('digitalsign') }}>{t('Sign Order')}</MenuItem>
                                            ) : <></>}
                                        </>
                                    ) : activeTab == 'accounting' ? (
                                        <>
                                            {invoices && invoices.map((inv) => <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'invoice/' + inv.id} >{t('Invoice PDF')+' '}{inv?.id}</a></MenuItem>)}
                                        </>
                                    ) : activeTab == 'order_processing' ? (
                                        <>
                                        {Cookies.get('permissions').indexOf("invoice-create") !== -1 ? (
                                            <>
                                                <MenuItem onClick={()=>{ closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                            </>
                                        ) : <></>}
                                        <MenuItem onClick={()=>{ closeMenu(); document.getElementById('SignDC').click() }}>{t('Sign DC')}</MenuItem>
                                        {/* <MenuItem onClick={()=>{ closeMenu(); document.getElementById('DCabname').click() }}>{t('DC Abnahmeprotokoll')}</MenuItem> */}
                                        
                                        {/* <MenuItem onClick={()=>{ closeMenu(); document.getElementById('ACabname').click() }}>{t('AC Abnahmeprotokoll')}</MenuItem> */}
                                        {Cookies.get('permissions').indexOf("ac_abnahmeprotokoll-read") !== -1 ? (
                                            <MenuItem onClick={()=>{ closeMenu(); document.getElementById('SignAC').click() }}>{t('Sign AC')}</MenuItem>
                                        ) : ''}

                                        {/*<MenuItem onClick={()=>{ closeMenu(); document.getElementById('dc_abnahmeprotokoll').click() }}>{t('Abnahmeprotokoll PDF')}</MenuItem>*/}

                                        <MenuItem onClick={closeMenu}>{t('Cancel Project')}</MenuItem>
                                        </>
                                    ) : <></>}
                                    

                                    <MenuItem onClick={closeMenu}  data-bs-toggle="modal" data-bs-target="#correctionRequest" >{t('Correction Request')}</MenuItem>
                                </>
                        </Menu>
                    </>
                ) : <></>}
            </div>
        </div>
        <ToastContainer />
        </>
    )
}

export default NavMenu;
